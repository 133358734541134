/**
 * This file contains the override css for leaflet
 *
*/

.leaflet-container {
    height: 100%;
    width: 100%;
    outline: 0;
    font: 1rem 'Manrope', sans-serif !important;
}

.leaflet-tile-container img {
    mix-blend-mode: plus-lighter;
}

.leaflet-top,
.leaflet-bottom {
    z-index: 1000;
}

.leaflet-fade-anim .leaflet-tile {
    will-change: opacity;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
    will-change: transform;
}


.leaflet-dragging-event .leaflet-interactive {
    cursor: -webkit-grabbing;
	cursor:    -moz-grabbing;
	cursor:         grabbing;
}

.leaflet-move .leaflet-interactive {
    cursor: -webkit-move;
    cursor:    -moz-move;
    cursor:         move;
}

.leaflet-ne-resize .leaflet-interactive {
    cursor: -webkit-ne-resize;
    cursor:     -moz-ne-resize;
    cursor:          ne-resize;
}

.leaflet-se-resize .leaflet-interactive {
    cursor: -webkit-se-resize;
    cursor:     -moz-se-resize;
    cursor:          se-resize;
}

.leaflet-nw-resize .leaflet-interactive {
    cursor: -webkit-nw-resize;
    cursor:     -moz-nw-resize;
    cursor:          nw-resize;
}

.leaflet-sw-resize .leaflet-interactive {
    cursor: -webkit-sw-resize;
    cursor:     -moz-sw-resize;
    cursor:          sw-resize;
}

.leaflet-ew-resize .leaflet-interactive {
    cursor: -webkit-ew-resize;
    cursor:     -moz-ew-resize;
    cursor:          ew-resize;
}

.leaflet-n-resize .leaflet-interactive {
    cursor: -webkit-n-resize;
    cursor:     -moz-n-resize;
    cursor:          n-resize;
}

.leaflet-e-resize .leaflet-interactive {
    cursor: -webkit-e-resize;
    cursor:     -moz-e-resize;
    cursor:          e-resize;
}

.leaflet-s-resize .leaflet-interactive {
    cursor: -webkit-s-resize;
    cursor:     -moz-s-resize;
    cursor:          s-resize;
}

.leaflet-w-resize .leaflet-interactive {
    cursor: -webkit-w-resize;
    cursor:     -moz-w-resize;
    cursor:          w-resize;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive {
    outline: none;
 }

.leaflet-container a {
    color: #0078a8;
}

.leaflet-bar {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
}

.leaflet-bar a {
    border-bottom: none;
}

.leaflet-control,
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
    font: 1rem 'Manrope', sans-serif;
}

.leaflet-control-layers-selector {
    margin-left: 5px;
    top: 0;
    margin-top: 0;
    cursor: pointer;
}

.leaflet-default-icon-path {
    background-image: url('http://soar.earth/assets/marker-icon.png');
    border: none;
}

.leaflet-marker-shadow {
    display: none;
}

.leaflet-container .leaflet-control-attribution {
    background: rgba(255, 255, 255, 0.7);
    margin: 0;
    font-size: 0.8rem;
    padding: 2px 5px !important;
}

.leaflet-control-attribution a:hover {
    text-decoration: underline;
}

.leaflet-control-scale-line {
    font-size: 11px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.5);
}

.leaflet-container a.leaflet-popup-close-button {
    width: 18px;
    height: 14px;
    font: 1rem 'Manrope', sans-serif !important;
    color: #c3c3c3;
    text-decoration: none;
    font-weight: bold;
    background: transparent;
}

.coordinate-builder-icon {
    background: transparent;
    border: none;
}

.leaflet-tooltip {
    box-shadow: none;
}

.leaflet-bottom.leaflet-left button#coordinate-control {
    left: -82px !important;
    min-width: 300px !important;
}

.leaflet-bottom.leaflet-left button#coordinate-control-button {
    left: 30px;
    background-color: rgb(0, 0, 0,0.8) !important;
    width: 312px !important;
    height: 90px;
    font-size: 1.1em !important;
    bottom: 4px;
    border-radius: 8px !important;
}

.leaflet-control-minimap.leaflet-control {
    z-index: 2000;
    left: 30px;
}

.leaflet-bottom.leaflet-left .leaflet-control-minimap.leaflet-control {
    position: absolute;
    transform: scale(0.8);
}

#coordinate-control-decimal-title-lat,
#coordinate-control-decimal-title-lng,
#coordinate-control-degrees-title-lat,
#coordinate-control-degrees-title-lng,
#coordinate-control-mgrs-title {
    float: left;
    margin-left: 98px;
    clear: both;
    color: white !important;
}

#coordinate-control-decimal-body-lat,
#coordinate-control-decimal-body-lng,
#coordinate-control-degrees-body-lat,
#coordinate-control-degrees-body-lng,
#coordinate-control-mgrs-body {
    float: right;
    margin-right: 20px !important;
    color: white !important;
}

#coordinate-control-decimal-title-lat,
#coordinate-control-decimal-body-lat,
#coordinate-control-degrees-title-lat,
#coordinate-control-degrees-body-lat {
    margin-bottom: 4% !important;
}

#coordinate-control-decimal-container,
#coordinate-control-mgrs-container,
#coordinate-control-degrees-container
{
    user-select: none;
}

#location-search-button:hover:after {
    content: "Location Search";
    width: 100px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    font-family: 'Manrope', sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding: 9px 0px 10px 0px;
    border-radius: 4px;
    z-index: 1000;
    left: -5.6rem;
    transform: translate(-23px, -10%);
}

#location-search-button:hover {
    cursor: pointer;
    background-color: rgb(52, 58, 64) !important;;
}