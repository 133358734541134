#box-zoom-button {
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2222%22%20height%3D%2222%22%20viewBox%3D%220%200%2022%2022%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Ccircle%20cx%3D%229.75004%22%20cy%3D%229.75001%22%20r%3D%224.91667%22%20stroke%3D%22white%22%2F%3E%0A%3Cpath%20d%3D%22M13.5%2013.5007L16.8334%2016.8337%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M1%207.66667V3C1%201.89543%201.89543%201%203%201H7.66667%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M1%2014.3333V19C1%2020.1046%201.89543%2021%203%2021H7.66667%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M21%207.66667V3C21%201.89543%2020.1046%201%2019%201H14.3333%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M21%2014.3333V19C21%2020.1046%2020.1046%2021%2019%2021H14.3333%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A');
    background-repeat: no-repeat !important;
    background-size: 24px !important;
    background-position: 8px 8px !important;
    margin: 0 !important;
    padding: 0 !important;

    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
}

#box-zoom-button:disabled, #box-zoom-button:disabled:hover {
    background-color: rgb(72, 78, 84) !important;
    opacity: 0.5;
}

#box-zoom-button:hover {
    background-color: rgb(52, 58, 64) !important;
}

#box-zoom-button:hover:after {
    content: "Box zoom";
    width: 72px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    font-family: 'Manrope', sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding: 9px 0px 10px 0px;
    border-radius: 4px;
    z-index: 1000;
    left: -5.6rem;
    transform: translate(5px, -50%);
}

.leaflet-top.leaflet-right {
    top: 94px !important;
    box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.3), 0px 23px 36px 3px rgba(0, 0, 0, 0.24),
        0px 9px 44px 8px rgba(0, 0, 0, 0.22);
    border: 1px solid #515151;
    border-radius: 6px;
}

.leaflet-control-zoom.leaflet-bar.leaflet-control a span {
    display: none;
}

.leaflet-control-zoom.leaflet-bar.leaflet-control a:hover {
    background-color: rgb(52, 58, 64) !important;
}

.leaflet-control-zoom.leaflet-bar.leaflet-control a.disabled {
    cursor: not-allowed !important;
    pointer-events: none !important;
    background-color: rgb(72, 78, 84) !important;
    opacity: 0.5;
}
