.leaflet-control-better-scale {
	height: 15px;
}

.leaflet-control-better-scale-ruler {
    position: relative;
    width: 100%;
    height: 100%;
}

.leaflet-control-better-scale-ruler-block {
    position: absolute;
    height: 100%;
    width: 100%;
    right:0;
}

.leaflet-control-better-scale.leaflet-control {
    position: absolute;
    height: 30px;
    right: 130px;
    bottom: 37px;
}

.leaflet-control-better-scale-ruler-block.leaflet-control-better-scale-metric {
    top: 50%;
    height: 50%;
    width: 100%;
    border-top: 2px solid white;
    border-left: 2px solid white;
}

.leaflet-control-better-scale-ruler-block.leaflet-control-better-scale-imperial {
    top: 0;
    height: 52%;
    width: 100%;
    margin-top: 1px;
    border-bottom: 2px solid white;
    border-left: 2px solid white;
}

.leaflet-control-better-scale-label {
    position: absolute;
    width: 100%;
    text-align: right;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    height: 5px;
    top: -1px;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}
