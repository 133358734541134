
.leaflet-pane.leaflet-annotation-control-pane {
    position: absolute;
    background: transparent;
    width: 100vw;
    height: 100px;
    bottom: 0;
    right: 0;
    z-index: 100000;
    user-select: none;
}


.leaflet-control-container .leaflet-right.leaflet-top button,
.leaflet-control-container .leaflet-left.leaflet-top a,
a.leaflet-search-control-a.leaflet-search-icon {
    width: 40px !important;
    height: 40px !important;
    background-color: rgba(0, 0, 0, 0.8) !important;
    border: none !important;
    display: block;
}

.leaflet-top.leaflet-right {
    top: 15px;
    right: 15px;
    color: white !important;
}

.leaflet-control-zoom.leaflet-bar.leaflet-control {
    margin: 0;
    vertical-align: top;
}

a.leaflet-control-zoom-in {
    user-select: none;
    background-color: rgba(0, 0, 0, 0.8) !important;
    width: 40px !important;
    height: 40px !important;
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2221%22%20height%3D%2221%22%20viewBox%3D%220%200%2021%2021%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Ccircle%20cx%3D%229%22%20cy%3D%229%22%20r%3D%228.5%22%20stroke%3D%22white%22%2F%3E%0A%3Cpath%20d%3D%22M15%2015L20%2019.9998%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M9%205V13%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M13%209L5%209%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A') !important;
    background-repeat: no-repeat !important;
    background-size: 24px !important;
    background-position: 8px 8px !important;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    margin: 0 !important;
    padding: 0 !important;
    color: transparent !important;
}
a.leaflet-control-zoom-in span {
    color: transparent !important;
}
a.leaflet-control-zoom-in:hover:after {
    content: "Zoom in";
    width: 72px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    font-family: 'Manrope', sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding: 3px;
    border-radius: 4px;
    z-index: 1000;
    left: -5.6rem;
    top: 24%;
    transform: translate(5px, -50%);
}

a.leaflet-control-zoom-out {
    user-select: none;
    background-color: rgba(0, 0, 0, 0.8) !important;
    width: 40px !important;
    height: 40px !important;
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2221%22%20height%3D%2221%22%20viewBox%3D%220%200%2021%2021%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Ccircle%20cx%3D%229%22%20cy%3D%229%22%20r%3D%228.5%22%20stroke%3D%22white%22%2F%3E%0A%3Cpath%20d%3D%22M15%2015L20%2019.9998%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%2F%3E%0A%3Cpath%20d%3D%22M13%209L5%209%22%20stroke%3D%22white%22%20stroke-linecap%3D%22round%22%2F%3E%0A%3C%2Fsvg%3E%0A') !important;
    background-repeat: no-repeat !important;
    background-size: 24px !important;
    background-position: 8px 8px !important;
    border-radius: 0px;
    margin: 0 !important;
    padding: 0 !important;
    color: transparent !important;
    margin-bottom: -4px;
}

a.leaflet-control-zoom-out:hover:after {
    content: "Zoom out";
    width: 72px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    font-family: 'Manrope', sans-serif;
    font-size: 12px;
    font-weight: 400;
    padding: 3px;
    border-radius: 4px;
    z-index: 1000;
    left: -5.6rem;
    top: 75%;
    transform: translate(5px, -50%);
}

.image-annotation-overlay::selection {
    background: transparent !important;
    user-select: none !important;
}

/* Stops user interaction with custom labels on annotations*/
.leaflet-svg-area-label {
    pointer-events: none;
    user-select: none;
}