
.leaflet-map  .leaflet-bottom.leaflet-right .leaflet-control-layers.leaflet-control:nth-of-type(3){
	display: none;
}

.leaflet-map.pinnedMaps  .leaflet-bottom.leaflet-right .leaflet-control-layers.leaflet-control:nth-of-type(3) {
	display: block;
}

.leaflet-map .leaflet-layer.pinned-tile-layer {
	display: none;
}
.leaflet-map.pinnedMaps .leaflet-layer.pinned-tile-layer {
	display: block;
}

.leaflet-map.pinnedMaps .leaflet-control.leaflet-control-better-scale  {
	margin-right: 50px;
}




