.node-dot-marker {
    z-index: 9999 !important;
}

.hidden-snap-point-node-marker {
    background-color: transparent;
    border-radius: 6px;
    width: 12px;
    height: 12px;
    z-index: 9999 !important;
}

.visible-snap-point-node-marker {
    background-color: #eed926;
    border-radius: 6px;
    width: 12px;
    height: 12px;
    z-index: 9999 !important;
}