::-webkit-file-upload-button {
    margin-top: 0px;
    margin-right: 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: #eed926;
    -webkit-text-fill-color: black;
    border-radius: 4px;
}
