.leaflet-control-coordinates-control {
    position: absolute;
    right: 240px;
    bottom: 10px;
    top: auto;

    display: flex;
    width: 160px;
    height: 50px;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 2px 10px;
}

.leaflet-control-coordinates-control:hover {
    background-color: rgba(0, 0, 0, 0.25);
}

.coordinates-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.coordinates-data strong, .coordinates-data span {
    font-size: 12px;
    font-weight: 600;
    color: white;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}