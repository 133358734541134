.annotation-tool-horizontal-distance-marker span{
    color: white;
    width: auto !important;
    height: auto !important;
    font-size: 1.2rem;
    font-weight: 500;
    text-shadow: 1px 3px 1px rgb(0 0 0);
    user-select: none !important;
    pointer-events: none !important;
}

.annotation-tool-vertical-distance-marker {
    width: auto !important;
    height: auto !important;
    user-select: none !important;
    pointer-events: none !important;
}

.annotation-tool-vertical-distance-marker span {
    display: block;
    color: white;
    -webkit-transform: rotate(90deg); /* Safari and Chrome */
    -moz-transform: rotate(90deg);   /* Firefox */
    -ms-transform: rotate(90deg);   /* IE 9 */
    -o-transform: rotate(90deg);   /* Opera */
    transform: rotate(90deg);
    font-size: 1.2rem;
    font-weight: 500;
    text-shadow: 1px 3px 1px rgb(0 0 0);
    user-select: none !important;
    pointer-events: none !important;
}

.annotation-tool-area-marker {
    text-align: center;
    user-select: none !important;
    pointer-events: none !important;
}

.annotation-tool-area-marker span {
    color: white;
    padding: 0px 3px !important;
    pointer-events: none;
    text-wrap: nowrap;
    font-size: 1.2rem;
    font-weight: 500;
    text-shadow: 1px 3px 1px rgb(0 0 0);
    user-select: none !important;
    pointer-events: none !important;
}


.annotation-tool-corner-handle  {
    background: transparent;
    width: 16px !important;
    height: 16px !important;  
}

.annotation-tool-corner-marker  {
    width: 16px !important;
    height: 16px !important;
    border-radius: 260px;
}

.annotation-tool-corner-marker.blue  {
    background: #3388ff;
}

.annotation-tool-corner-marker.red  {
    background: red;
}

.annotation-tool-corner-marker.orange  {
    background: orange;
}

.annotation-tool-corner-marker.yellow  {
    background: yellow;
}

.annotation-tool-corner-marker.green  {
    background: green;
}